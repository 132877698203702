//-------------------------------------------------------------------- //
// Document single
//-------------------------------------------------------------------- //
.card {

    &--document {
        margin-bottom: 20px;

        &__button {
            margin: 20px 0 0 0;
        }

        .accordion--transcription {
            margin: 0 0 20px 0;
        }
    }

    &__container {
        &--document {
            min-height: 0;
        }
    }

    &__content {
        &--document {
            display: block;
            color: $color--card;
        }
    }
}


//=================================================================================================
// Image par défaut
//=================================================================================================
.image-wrapper {
    &.documents,
    &.documents_category {
        background-image: url(/wp-content/themes/noyau/assets/images/icon-documents.png);
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
// @media screen and (max-width: $large) {

//     .card--document {
//         .accordion--transcription {
//             margin-top: 25px;
//         }
//     }

// }
