.front-page {
    position: relative;
    overflow: hidden;
    z-index: 0;
    background: $color-white;

    &__title {
        border: none;
        text-transform: uppercase;
        font-family: $font-family--heading;
        font-weight: $font-weight-semibold;
        font-size: 90px;
        line-height: 100px;
        margin: 0;
        padding: 0;
    }

    &__button {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        transition: all $duration ease-in-out;

        p {
            color: $color-main;
            font-family: $font-family;
            font-style: italic;
            font-weight: $font-weight-normal;
            font-size: $font-size--text;
            text-transform: uppercase;
            line-height: 22px;
            position: relative;

            &:before {
                position: absolute;
                content: "";
                width: 0%;
                height: 1px;
                bottom: -10px;
                right: 0;
                background-color: $color-main;
                transition: all 0.4s ease-in-out;
            }
        }

        div {
            width: 50px;
            height: 50px;
            background-color: $color-main;
            clip-path: polygon(7% 25%,52% 0%,95% 25%,95% 75%,53% 100%,7% 75%);
            -webkit-clip-path: polygon(7% 25%,52% 0%,95% 25%,95% 75%,53% 100%,7% 75%);
            transition: all $duration ease-in-out;

            svg {
                width: 100%;
                height: 100%;
                fill: $color-white;
                transition: all $duration ease-in-out;
            }
        }

        &:hover, &:focus {
            p:before {
                width: 100%;
            }

            div {
                background-color: $color-third;
                
                svg {
                    fill: $color-dark;
                }
            }
        }
    }
}


//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    position: relative;

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--navigation, &--pagination {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
        }
    }

    .slideshow {
        height: 500px;
        width: 100%;

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: top;
            width: 100%;
            height: 100%;
        }

        &__container {
            display: inherit;
            position: relative;
            width: 100%;
            height: 100%;

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg,rgba(0, 0, 0, 0.5) 40%,rgba(0, 0, 0, 0));
                z-index: 1;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            position: absolute;
            left: 30px;
            bottom: 185px;
            width: 370px;
            height: auto;
            color: $color-white;
            z-index: 3;
        }

        &__title {
            margin-bottom: 15px;
            font-size: $font-size--4;
            font-weight: $font-weight-bold;
            text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.8);
            font-family: $font-family--heading;
            font-weight: $font-weight-semibold;
            font-size: $font-size--6;
            line-height: 28px;
        }

        &__description {
            text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.8);
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: $font-size--5;
            line-height: 22px;
        }

        &--video {
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 15px;
            bottom: 150px;
            z-index: 2;
            width: auto;
    
            .swiper-pagination-bullet {
                width: 12px;
                height: 12px;
                margin: 0 10px 0 0;
                transition: .25s;
                background-color: $color-white;
                border-radius: 50px;
                opacity: 1;
    
                &:hover, &:focus {
                    background-color: $color-third;
                }
    
                &-active {
                    mask-image: url(/wp-content/themes/egletons/assets/src/images/sprite-images/home/forme-alu.svg);
                    background-color: $color-white;
                    width: 20px;
                    height: 20px;
                    mask-size: 20px;
                    mask-repeat: no-repeat;
                }
            }
        }
    
    }

    .search-tools {
        position: absolute;
        left: calc(50% + -570px);
        top: 400px;
        z-index: 1;
        padding: 0;

        .search-svg {
            position: relative;
            width: auto;
            height: auto;
            top: -1px;

            &:before {
                position: absolute;
                content: "";
                mask-image: url(/wp-content/themes/egletons/assets/src/images/sprite-images/home/forme.svg);
                background-color: $color-white;
                top: 0;
                left: 0;
                width: 50px;
                height: 50px;
                mask-repeat: no-repeat;
                z-index: 1;
            }

            svg {
                width: 50px;
                height: 50px;
                fill: $color-white;
            }
        }

        .searchHome {
            padding: 0;
            border: none;
            border-radius: 0;
            width: 515px;
            margin-left: 5px;
            border-bottom: 1px solid $color-white;
            margin-top: 15px;
            padding-bottom: 10px;
            color: $color-white;
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: $font-size--text;
            font-style: italic;

            &::placeholder {
                color: $color-white;
                font-family: $font-family;
                font-weight: $font-weight-normal;
                font-size: $font-size--text;
                font-style: italic;
                line-height: 22px;
                opacity: 1;
            }
        }

        .searchResult {
            border-top: none;
            filter: drop-shadow(0 0 5px rgba(0,0,0,0.1));
            width: 515px;
            left: 55px;
        }
    }
}

//======================================================================================================
// Accès rapides 
//======================================================================================================

.access {
    margin: 80px 0 100px;
    
    &__list {
        display: flex;
        justify-content: space-between;
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
            width: 130px;
            height: 130px;
            background-color: $color-main;
            clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
            -webkit-clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
            transition: all $duration ease-in-out;
        }

        span {
            margin-top: 15px;
            font-family: $font-family;
            font-weight: $font-weight-bold;
            font-size: $font-size--text;
            line-height: 22px;
            color: $color-dark;
            text-transform: uppercase;
            text-align: center;
        }

        &:hover, &:focus {
            div {
                background-color: $color-second;
                transform: scale(1.1);

                svg path  {
                    fill: $color-main;
                }
            }
        }
    }
}

//======================================================================================================
// Actualités 
//======================================================================================================

.news {
    background-color: $color-fourth;
    position: relative;
    padding: 60px 0;

    &:before {
        position: absolute;
        content: "";
        width: 200px;
        height: 200px;
        top: 0;
        left: calc(50% + -960px);
        background-color: $color-white;
        clip-path: polygon(0 0, 0% 100%, 100% 0);
        -webkit-clip-path: polygon(0 0, 0% 100%, 100% 0);
    }

    &__title {
        color: $color-white;
        text-align: end;

        &--small {
            margin: 20px 0 10px;
            color: $color-dark;
            font-family: $font-family--heading;
            font-weight: $font-weight-semibold;
            font-size: $font-size--6;
            line-height: 28px;
        }
    }

    &__list {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
    }

    &__item {
        width: 570px;
    }

    &__link {
        &:hover, &:focus {
            .news__image div {
                transform: scale(1.1);
                background-color: $color-main;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    &__image {
        position: relative;
        width: 570px;
        height: 380px;
        overflow: hidden;
        
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat $color-light;
            background-position: center;
            width: 570px;
            height: 380px;
        }

        div {
            position: absolute;
            bottom: 20px;
            right: 20px;
            width: 50px;
            height: 50px;
            background-color: $color-third;
            clip-path: polygon(7% 25%,52% 0%,95% 25%,95% 75%,53% 100%,7% 75%);
            -webkit-clip-path: polygon(7% 25%,52% 0%,95% 25%,95% 75%,53% 100%,7% 75%);
            transition: all $duration ease-in-out;

            svg {
                width: 100%;
                height: 100%;
                transition: all $duration ease-in-out;
            }
        }
    }

    &__introduction {
        font-family: $font-family;
        font-weight: $font-weight-normal;
        font-size: $font-size--5;
        line-height: 22px;
        color: $color-dark;
    }

    &__button {
        margin-top: 55px;
    }
}

//======================================================================================================
// Évènements
//======================================================================================================

.home-events {
    margin: 80px 0 90px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        color: $color-main;
    }

    &__navigation {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 110px;
        height: 50px;

        &__prev,
        &__next {
            position: relative;
            top: unset;
            right: unset;
            left: unset;
            bottom: unset;
            margin-top: unset;
            width: 50px;
            height: 50px;
            margin: 0;
            padding: 0;
            background-color: $color-third;
            clip-path: polygon(7% 25%,52% 0%,95% 25%,95% 75%,53% 100%,7% 75%);
            -webkit-clip-path: polygon(7% 25%,52% 0%,95% 25%,95% 75%,53% 100%,7% 75%);
            transition: all $duration ease;

            &::after {
                position: relative;
                content: "";
                top: 0;
                left: 0;
                width: 50px;
                height: 50px;
                mask-repeat: no-repeat;
                background-color: $color-dark;
                transition: all $duration ease;
            }

            &:hover, &:focus {
                background-color: $color-fifth;
            }
        }

        &__prev {
            
            &::after {
                mask-image: url(/wp-content/themes/egletons/assets/src/images/sprite-images/Pictos/ic-nav-left.svg);
                
            }
        }

        &__next {
            &::after {
                mask-image: url(/wp-content/themes/egletons/assets/src/images/sprite-images/Pictos/ic-nav-right.svg);
            }
        }
    }

    &__swiper {
        margin-top: 40px;
    }

    &__link {
        display: flex;
        align-items: center;

        &:hover, &:focus {
            .home-events__content {
                background-color: $color-second;
            }

            .home-events__info h3 {
                color: $color-main;
            }

            .home-events__date {
                color: $color-main;
            }

            .home-events__image {
                margin-left: -80px;
            }
        }
    }

    &__content {
        width: 370px;
        height: 370px;
        background-color: $color-main;
        transition: all 0.4s ease;
    }

    &__info {
        padding: 50px 100px 50px 30px;

        h3 {
            font-family: $font-family--heading;
            font-weight: $font-weight-semibold;
            font-size: $font-size--6;
            line-height: 28px;
            color: $color-white;
            transition: all 0.4s ease;
        }
    }

    &__date {
        font-family: $font-family--heading;
        font-weight: $font-weight-bold;
        font-size: 45px;
        line-height: 50px;
        color: $color-white;
        text-transform: uppercase;
        display: flex;
        flex-wrap: wrap;
        transition: all 0.4s ease;

        span {
            margin-left: 10px;
        }

        p {
            display: flex;
        }

        p:last-child {
            width: 100%;
        }
    }

    &__image {
        position: relative;
        width: 270px;
        height: 300px;
        overflow: hidden;
        margin-left: -70px;
        transition: all 0.4s ease;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .notimg {
        background: url('/wp-content/themes/noyau/assets/images/icon-events.png') no-repeat $color-light;
        background-position: center;
        position: relative;
        width: 270px;
        height: 300px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 40px;
        margin-top: 40px;
    }

    &__button:first-child {
        div {
            clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
            -webkit-clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
            background-color: transparent;
            position: relative;
            width: 50px;
            height: 50px;

            &:before {
                position: absolute;
                content: "";
                background-image: url(/wp-content/themes/egletons/assets/src/images/sprite-images/home/forme.svg);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                z-index: 1;
            }

            svg {
                background-color: unset;
                width: 50px;
                height: 50px;
                fill: $color-main;
            }
        }

        &:hover, &:focus {
            div {
                background-color: $color-main;
                

                &:before {
                    display: none;
                }

                svg {
                    fill: $color-white;
                }
            }
        }
    }
}

//======================================================================================================
// Documents 
//======================================================================================================

.documents {
    background-color: $color-fifth;
    position: relative;
    height: 500px;
    padding: 60px 0 65px;

    &:before {
        position: absolute;
        content: "";
        width: 200px;
        height: 200px;
        bottom: 0;
        left: calc(50% + 760px);
        background-color: $color-white;
        clip-path: polygon(100% 100%, 0% 100%, 100% 0);
        -webkit-clip-path: polygon(100% 100%, 0% 100%, 100% 0);
    }

    &__item {
        display: flex;
    }

    &__title {
        color: $color-dark;

        &--small {
            font-family: $font-family--heading;
            font-weight: $font-weight-semibold;
            font-size: $font-size--6;
            line-height: 28px;
            color: $color-dark;
            margin: 50px 0 15px;
            max-width: 270px;
        }
    }

    &__image {
        display: flex;
        justify-content: flex-end;
        width: 350px;
        height: 500px;
        margin-top: 20px;
        margin-right: 100px;

        &--no-image {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $color-bg--image;
        
            svg {
              width: 40%;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__content {
        width: 50%;
    }

    &__extension span {
        text-transform: uppercase;
    }

    &__buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        &--left {
            display: flex;
            gap: 10px;

            .link-document {
                margin: 0;
                width: 50px;
                height: 50px;
                clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
                -webkit-clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
                background-color: transparent;
                position: relative;
    
                &:before {
                    position: absolute;
                    content: "";
                    mask-image: url(/wp-content/themes/egletons/assets/src/images/sprite-images/home/forme.svg);
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    mask-repeat: no-repeat;
                    background-color: $color-white;
                    z-index: 1;
                }
    
                svg {
                    background-color: unset;
                    width: 100%;
                    height: 100%;
                    margin-right: 0;
                }
    
                &:hover, &:focus {
                    background-color: $color-white;
                }
            }
        }
    }

    &__button {
        p {
            color: $color-dark;

            &:before {
                background-color: $color-dark;
            }
        }

        svg {
            background-color: $color-dark;
        }

        &:hover, &:focus {
            svg {
                background-color: $color-white;
            }
        }
    }
}

//======================================================================================================
// Social Wall
//======================================================================================================

.social-wall {
    margin: 80px 0;

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        gap: 20px;
        margin-bottom: 90px;
    }

    &__pictos {
        display: flex;
        gap: 10px;
        
        a {
            width: 50px;
            height: 50px;
            clip-path: unset;
            transition: all $duration ease-in-out;
            clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
            -webkit-clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
            background-color: transparent;
            position: relative;

            &:before {
                position: absolute;
                content: "";
                mask-image: url(/wp-content/themes/egletons/assets/src/images/sprite-images/home/forme.svg);
                background-color: $color-third;
                top: 0;
                left: 0;
                width: 50px;
                height: 50px;
                mask-repeat: no-repeat;
                opacity: 1;
                z-index: 1;
                transition: all $duration ease-in-out;
            }

            svg {
                fill: $color-main;
                transition: all $duration ease-in-out;
            }

            &:hover, &:focus {
                background-color: $color-third;
    
                svg {
                    fill: $color-dark;
                }
    
                &:before {
                    opacity: 0;
                }
            }
        }
    }

    #ff-stream-1 {
        background-color: unset;
        min-height: auto!important;
        padding: 0;

        .ff-header,
        .ff-loadmore-wrapper,
        .ff-carousel-icon,
        .ff-video-icon {
            display: none;
        }

        .ff-stream-wrapper article:nth-child(2) {
            margin-left: 30px;
        }

        .ff-item {
            width: 570px!important;

            &:hover, &:focus {
                background: unset !important;
            }

            .picture-item__inner {
                box-shadow: unset;
                background: unset;

                &:before {
                    display: none;
                }

                &:hover, &:focus {
                    background: unset !important;
                }
            }

            &-cont {
                position: relative;
                height: auto;

                .ff-img-holder {
                    width: 260px;
                    height: 240px;
                    position: absolute;
                    left: 0;
                    transform: translateX(0);
                    transition: all 0.4s ease-in-out;

                    img {
                        height: 100%;
                        object-fit: cover;
                        min-height: unset !important;
                    }
                }

                .ff-content {
                    width: 335px;
                    height: 335px!important;
                    position: absolute;
                    right: 0;
                    margin: 0;
                    padding: 40px 35px 40px 50px;
                    top: -45px;
                    background: $color-main;
                    color: $color-white;
                    font-family: $font-family;
                    font-weight: $font-weight-light;
                    font-size: $font-size--text;
                    line-height: 22px;
                    transition: all $duration ease-in-out;
                }
            }

            .ff-item-bar {
                width: 335px;
                float: right;
                margin-top: 65px;
                border-top: none;
                background: $color-main;
                height: 60px;
                padding: 30px 7% 60px;
                min-height: 60px;
                transition: all $duration ease-in-out;

                a, 
                .ff-share-wrapper {
                    color: $color-white!important;
                    transition: all $duration ease-in-out;
                }
            }

            &:hover, &:focus {
                .ff-content {
                    color: $color-main;
                    background: $color-second;
                }

                .ff-img-holder {
                    transform: translateX(10px);
                }

                .ff-item-bar {
                    background: $color-second;

                    a, 
                    .ff-share-wrapper {
                        color: $color-main!important;
                    }
                }
            }
        }
    }
}

//======================================================================================================
// Partenaires
//======================================================================================================

.admin-area--1 {
    position: relative;

    &:before {
        position: absolute;
        content: "";
        width: 870px;
        height: 100%;
        background-color: $color-fourth;
        left: calc(50% + -960px);
        top: 0;
        clip-path: polygon(0 0,100% 0,70% 100%,0 100%);
        -webkit-clip-path: polygon(0 0,100% 0,70% 100%,0 100%);
        z-index: 2;
    }

    &:after {
        position: absolute;
        content: "";
        width: 460px;
        height: 15px;
        background-color: $color-white;
        top: 110px;
        left: calc(50% + -425px);
        transform: rotate(314deg);
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        z-index: 2;
    }

    .swiper-container {
        padding-left: calc(50% + -15px);
    }

    .bloc {
        width: 315px!important;
        height: 270px;

        &__wrapper {
            display: flex;
            justify-content: center;
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__image {
            position: relative;

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.2));
                z-index: 1;
                transition: all $duration ease-in-out;
            }
        }

        &__content {
            position: absolute; 
            bottom: 30px;
            color: $color-white;
            font-family: $font-family;
            font-weight: $font-weight-bold;
            font-size: $font-size--text;
            line-height: 22px;
            text-align: center;
            text-transform: uppercase;
            text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
            z-index: 1;
        }

        &:hover, &:focus {
            .bloc__image {
                &:before {
                    background: linear-gradient(rgba(0,0,0,.6),rgba(0,0,0,.6));
                }
            }
        }
    }

    &__navigation {
        display: flex;
        align-items: center;
        gap: 10px;
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        left: calc(50% + -585px);

        &__prev,
        &__next {
            position: relative;
            top: unset;
            right: unset;
            left: unset;
            bottom: unset;
            margin-top: unset;
            width: 50px;
            height: 50px;
            margin: 0;
            padding: 0;
            background-color: $color-white;
            clip-path: polygon(7% 25%,52% 0%,95% 25%,95% 75%,53% 100%,7% 75%);
            -webkit-clip-path: polygon(7% 25%,52% 0%,95% 25%,95% 75%,53% 100%,7% 75%);
            transition: all $duration ease;

            &::after {
                position: relative;
                content: "";
                top: 0;
                left: 0;
                width: 50px;
                height: 50px;
                mask-repeat: no-repeat;
                background-color: $color-dark;
                transition: all $duration ease;
            }

            &:hover, &:focus {
                background-color: $color-fifth;
            }
        }

        &__prev {
            
            &::after {
                mask-image: url(/wp-content/themes/egletons/assets/src/images/sprite-images/Pictos/ic-nav-left.svg);
                
            }
        }

        &__next {
            &::after {
                mask-image: url(/wp-content/themes/egletons/assets/src/images/sprite-images/Pictos/ic-nav-right.svg);
            }
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .front-page {
        &__title {
            font-size: 80px;
        }
    }
    
    .home-slideshow {
        .slideshow {
            height: 400px;

            &__container {
                &:before {
                    background: linear-gradient(90deg,rgba(0,0,0,.6) 0%,transparent);
                }
            }

            &__content {
                left: 55px;
            }

            &__pagination {
                left: 45px;
            }
        }

        .search-tools {
            left: calc(50% + -420px);
            top: 300px;

            .searchHome {
                width: 515px;
            }
        }
    }

    .access {
        margin: 80px 0;
    }

    .news {

        &:before {
            width: 110px;
            height: 110px;
            left: calc(50% + -600px);
        }
        
        &__item {
            width: 460px;
        }

        &__image {
            width: 460px;
            height: 305px;
    
            &--notimg {
                width: 460px;
                height: 305px;
            }
        }

        &__button {
            margin-top: 25px;
        }
    }

    .home-events {
        &__content {
            width: 300px;
            height: 300px;
        }

        &__image {
            width: 220px;
            height: 240px;
            margin-left: -60px;
        }
    
        .notimg {
            width: 220px;
            height: 240px;
        }

        &__swiper {
            margin-top: 50px;
        }

        &__date {
            font-size: 40px;
        }
    }

    .documents {
        height: 480px;
        padding: 60px 0 60px;

        &:before {
            width: 110px;
            height: 110px;
            left: calc(50% + 490px);
        }

        &__image {
            width: unset;
            margin-right: 0;

            &--no-image {
                margin: 5px 60px 0 40px;
            }

            img {
                width: 300px;
                height: 440px;
                margin: 5px 60px 0 40px;
            }
        }

        &__content {
            width: 550px;
        }

        &__buttons {
            margin-top: 25px;
        }
    }

    .social-wall {
        margin: 100px 0 80px;

        &__pictos {
            gap: 5px;
        }

        #ff-stream-1 {
            article:nth-child(2) {
                margin-left: 25px;
            }

            .ff-item {
                width: 460px !important;

                .ff-item-bar {
                    width: 275px;
                    margin-top: 33px;
                    padding: 30px 7% 43px;
                }
            }

            .ff-item-cont {
                .ff-img-holder {
                    width: 200px;
                    height: 185px;
                }
    
                .ff-content {
                    width: 275px;
                    height: 275px !important;
                    padding: 35px 15px 40px 35px;
                }
            }
        }
    }

    .admin-area--1 {
        &__navigation {
            left: calc(50% - 470px);
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .front-page {
        &__title {
            font-size: 60px;
        }
    }

    .home-slideshow {
        .slideshow {
            height: 320px;

            &__container {
                background: linear-gradient(90deg,rgba(0,0,0,.6) 35%,transparent);
            }

            &__content {
                left: 20px;
                bottom: 85px;
            }

            &__pagination {
                left: 10px;
                bottom: 50px;
            }
        }

        .search-tools {
            display: none;
        }
    }

    .access {
        margin: 60px 0;

        &__list {
            flex-wrap: wrap;
            justify-content: center;
            gap: 40px 57px;
        }
    }

    .news {
        &:before {
            width: 150px;
            height: 150px;
            left: calc(50% + -480px);
        }

        &__list {
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 40px;
        }

        &__item {
            width: 570px;
        }

        &__image {
            width: 570px;
            height: 380px;
    
            &--notimg {
                width: 570px;
                height: 380px;
            }
        }

        &__button {
            margin-top: 55px;
        }
    }

    .home-events {
        margin: 80px 0;

        &__swiper {
            margin-top: 40px;
        }

        &__link {
            flex-direction: column;

            &:hover, &:focus {
                .home-events__image {
                    margin-left: 0;
                }
            }
        }

        &__info {
            padding: 30px 30px 80px;
        }

        &__image {
            width: 260px;
            height: 290px;
            margin-left: 0;
            margin-top: -30px;
        }
    
        .notimg {
            width: 260px;
            height: 290px;
        }
    }

    .documents {
        padding: 20px 0;
        height: 430px;

        &:before {
            width: 150px;
            height: 150px;
            left: calc(50% + 330px);
            bottom: -1px;
        }

        &__image {
            margin: 10px 20px 0 0;
            width: 300px;
            height: 435px;
            min-width: 300px;
            max-width: 300px;
            img {
                width: 100%;
                height: 100%;
                margin: 0;
            }
        }

        &__title {
            &--small {
                margin: 20px 0;
            }
        }

        &__buttons {
            flex-wrap: wrap;
            gap: 20px;
        }
    }

    .social-wall {
        &__header {
            margin-bottom: 0;
        }

        #ff-stream-1 {
            .ff-stream-wrapper {
                margin: 30px 0 0;

                article:nth-child(2) {
                    margin-left: 20px;
                }
            }

            .ff-item {
                width: 300px !important;
                height: 500px !important;

                .ff-item-bar {
                    margin-top: 329px;
                    width: 300px;
                    height: 69px;
                    padding: 31px 7% 10px;
                }

                &:hover, &:focus {
                    .ff-img-holder {
                        transform: translateX(-50%);
                    }
                }
            }

            .ff-item-cont {
                .ff-img-holder {
                    width: 260px;
                    height: 240px;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 0;
                }

                .ff-content {
                    width: 300px;
                    height: 300px !important;
                    top: 200px;
                    padding: 55px 20px 0 20px;
                }
            }
        }
    }

    .admin-area--1 {
        &:before {
            left: calc(50% - 835px);
        }

        &:after {
            left: calc(50% - 300px);
        }

        .swiper-container {
            padding-left: calc(50% - -110px);
        }

        &__navigation {
            left: calc(50% - 310px);
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .front-page {
        &__title {
            font-size: 45px;
            height: 80px;
            display: flex;
            align-items: flex-end;
        }
    }

    .home-slideshow {
        .slideshow {
            height: 220px;
            overflow: unset;

            &__content {
                bottom: 25px;
                max-width: 300px;
            }

            &__title  {
                font-size: $font-size--text-medium;
            }

            &__pagination {
                bottom: -40px;

                .swiper-pagination-bullet {
                    background-color: $color-main;
                }
            }
        }
    }

    .access {
        padding: 90px 0 60px;
        margin: 0;

        &__list {
            gap: 40px 0;
            justify-content: space-between;
        }
    }

    .news {
        &:before {
            width: 150px;
            height: 150px;
            left: calc(50% + -320px);
        }

        &__list {
            margin-top: 0;
        }

        &__item {
            width: 300px;
        }

        &__image {
            width: 300px;
            height: 200px;
    
            &--notimg {
                width: 300px;
                height: 200px;
            }
        }

        &__button {
            margin-top: 50px;
        }
    }

    .home-events {
        &__header {
            flex-direction: column;
            align-items: flex-start;
        }

        &__swiper {
            margin-top: 30px;
        }

        &__buttons {
            flex-direction: column;
            gap: 20px;
        }
    }

    .documents {
        height: 925px;
        padding: 140px 0 60px;
        position: relative;

        &:before {
            width: 150px;
            height: 150px;
            left: calc(50% + 170px);
        }

        &__item {
            flex-direction: column;
        }

        &__title {
            position: absolute;
            top: 35px;
        }

        &__content {
            max-width: 300px;
        }

        &__buttons {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .social-wall {
        margin: 80px 0;

        &__header {
            flex-direction: column-reverse;
            gap: 0;
            align-items: flex-end;
        }

        &__title {
            height: 95px;
        }

        #ff-stream-1 {
            .ff-stream-wrapper article:nth-child(2) {
                margin-left: 0;
            }

            .ff-item {
                position: relative;
                
                .picture-item__inner {
                    position: unset;
                }

                .ff-item-bar {
                    margin-top: 0;
                    position: absolute;
                    bottom: 0;
                }
            }
        }
    }

    .admin-area--1 {
        &:before {
            left: calc(50% - 765px);
        }

        &:after {
            left: calc(50% - 235px);
        }

        .swiper-container {
            padding-left: calc(50% + 165px);
        }

        &__navigation {
            left: calc(50% - 150px);
            top: 25px;
            transform: unset;
        }
    }
}
