.cover {
    position: relative;

    &__image-wrapper {
        width: 100%;
        height: 500px;
        background: $color-bg--image;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background: $color-fourth;
        padding: 40px 0 60px;
        position: relative;

        &:before {
            position: absolute;
            content: "";
            width: 150px;
            height: 150px;
            bottom: 0;
            left: calc(50% + 810px);
            background-color: $color-white;
            clip-path: polygon(100% 100%, 0% 100%, 100% 0);
            -webkit-clip-path: polygon(100% 100%, 0% 100%, 100% 0);
        }
    }

    &__title {
        color: $color-white;
        margin: 0 0 20px;
    }

    &__lead-wrapper {
        p {
            font-family: $font-family;
            font-weight: $font-weight-semibold;
            font-size: $font-size--text-medium;
            line-height: 24px;
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {
        &__informations {
            &:before {
                left: calc(50% + 450px);
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .cover {
        &__image-wrapper {
            height: 380px;
        }

        &__informations {
            &:before {
                left: calc(50% + 330px);
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .cover {

        &__informations {
            &:before {
                width: 100px;
                height: 100px;
                left: calc(50% + 220px);
            }
        }
        
        &__lead-wrapper {
            p {
                font-weight: $font-weight;
            }
        }

        &__title {
            line-height: 35px;
        }
        
        &__image-wrapper {
            height: 255px;
        }
    }

}
