.header {
    position: relative;

    &--fixed {
        .header-top {
            height: 110px;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            padding: 0;
            z-index: 20;
            background-color: $color-white;
            box-shadow: 0 0 1px rgba(27,35,54,.08),1px 1px 10px rgba(27,35,54,.08);
            transition: all 0.1s ease-in-out;
        }
    }
}

.header-top {
    position: relative;
    height: 110px;

    &:before {
        position: absolute;
        content: "";
        width: 680px;
        height: 100%;
        background-color: $color-fifth;
        right: calc(50% + -960px);
        top: 0;
        clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
        -webkit-clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
        z-index: -1;
        transition: all $duration ease-in-out;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .identity {
            height: 110px;
            display: flex;
            align-items: center;
        }
    }

    &__title {
        margin: 0;
        display: flex;
        align-items: center;
        font-size: 0;
        line-height: 0;
    }

    &__pictos {
        display: flex;
        gap: 10px;
        margin-right: -215px;
    }

    #cdma {
        display: flex;
        align-items: center;
        gap: 20px;

        p {
            font-family: $font-family;
            font-style: italic;
            font-weight: $font-weight-normal;
            font-size: $font-size--text;
            line-height: 22px;
            color: $color-dark;
            text-transform: uppercase;
            width: fit-content;
            position: relative;
            margin-top: -5px;

            &:before {
                position: absolute;
                content: "";
                width: 0%;
                height: 1px;
                bottom: -10px;
                right: 0;
                background-color: $color-dark;
                transition: all $duration ease-in-out;
            }
        }

        div {
            width: 50px;
            height: 50px;
            background-color: $color-dark;
            clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
            -webkit-clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
            transition: all $duration ease-in-out;
        }

        &:hover, &:focus {
            p:before {
                width: 100%;
            }

            div {
                background-color: $color-main;
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================

#uci_link{
    display: none;
}

.tools {
    &__content {
        display: flex;
        gap: 10px;
    }

    &__search {
        &--disabled {
            pointer-events: none;
            opacity: .2;
        }
    }

    .tool {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        padding: 0;
        background-color: transparent;
        clip-path: polygon(5% 25%,50% 0,92% 25%,92% 75%,50% 100%,5% 75%);
        -webkit-clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
        border: 2px solid transparent;
        margin: 0;
        transition: all $duration ease-out;
        min-height: 50px;
        max-height: 50px;

        &:before {
            position: absolute;
            content: "";
            background-image: url(/wp-content/themes/egletons/assets/src/images/sprite-images/home/forme.svg);
            top: -1px;
            left: -2px;
            width: 49px;
            height: 49px;
            background-size: 49px;
            background-repeat: no-repeat;
            z-index: 1;
        }

        svg {
            fill: $color-main;
            width: 50px;
            height: 50px;
        }

        &:hover, &:focus {
            background-color: $color-main;
    
            svg {
                fill: $color-white;
            }
        }
    }
}


//======================================================================================================
// Privacy Policy Banner
//======================================================================================================
.privacy {
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
    background: $color-black--rgba;
    color: $color-white;
    transition: all $duration;

    &__container {
        display: flex;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &__title {
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
    }

    &__info {
        font-size: $font-size--text-small;
        padding-right: 20px;
    }

    &__link {
        text-decoration: underline;

        &:hover, &:focus {
            color: $color-white;
            text-decoration: none;
        }
    }

    &__button {
        width: 240px;
        margin-left: 15px;
        background-color: $btn-privacy-bg;
        color: $btn-privacy-color;

        &:hover, &:focus {
            background-color: $color-main;
            color: $color-white; 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header-top {
        &:before {
            right: calc(50% - 825px);
        }

        &__pictos {
            margin-right: 40px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .header-top {
        &:before {
            right: calc(50% - 650px);
        }

        #cdma {
            p {
                display: none;
            }
        }

        &__container {
            .identity  {
                height: 50px;

                h1 {
                    margin: 0;

                    svg {
                        width: 300px;
                        height: 50px;
                    }
                }
            }
        }

        &__pictos {
            margin-right: -98px;
            gap: 5px;
        }
    }

    .tools {
        &__content {
            gap: 5px;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .header-top {
        height: 240px;

        &:before {
            width: 100%;
            height: 110px;
            left: 0;
            bottom: 0;
            top: unset;
            right: unset;
            clip-path: unset;
        }

        &__container {
            flex-wrap: wrap;
            justify-content: center;

            .identity  {
                height: 110px;
                margin-top: -25px;
                display: flex;
                align-items: center;

                h1 {
                    margin: 0;
                    height: 50px;
                }
            }
        }

        &__pictos {
            margin-right: 6px;
            margin-top: -25px;
        }

        #cdma {
            margin-top: -25px;
        }
    }

    .privacy {
        &__container {
            flex-direction: column;
            align-items: flex-start;
        }

        &__button  {
            margin: 0;
            margin-top: 20px;
        }
    }
}
