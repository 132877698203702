@keyframes menuClose {
    from {
        display: none;
        opacity: 0;
    }
    20% {
        display: flex;
        opacity: 0;
    }
    70% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.nav-main {
    width: 50px;
    height: 50px;

    &__container {
        position: fixed;
        right: 0;
        top: 0;
        display: flex;
        justify-content: flex-end;
        transform: translateY(-630px);
        transition: .5s ease-in-out;
        z-index: 11;
        height: 630px;
        width: 100vw;
        background: $color-main;

        .container {
            position: relative;
            width: 100%;

            svg {
                margin-top: 35px;
                path:first-child {
                    fill: $color-third;
                }
                path:not(:first-child) {
                    fill: $color-white;
                }
            }

            &:before {
                position: absolute;
                content: "";
                width: 97%;
                height: 1px; 
                left: 15px;
                top: 135px;
                background-color: $color-white;
                z-index: 2;
            }
        }
    }

    .menu {
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100vw;
        z-index: 15;
        padding-top: 100px;
        width: 350px;
        height: 100%;
        transition: .35s ease-in-out;

        .nav-main-item:nth-child(2) {
            .sub-menu {
                top: -80px;
            }
        }

        .nav-main-item:nth-child(3) {
            .sub-menu {
                top: -160px;
            }
        }

        .nav-main-item:nth-child(4) {
            .sub-menu {
                top: -240px;
            }
        }

        .nav-main-item {
            position: relative;

            &:hover {
                .sub-menu {
                    display: flex;
                }

                .nav-main-link {
                    color: $color-main;
                    background-color: $color-white
                }
            }

            a {
                transition: all 1.7s ease-in-out;
            }

            .nav-main-link {
                position: relative;
                display: flex;
                align-items: center;
                font-family: $font-family--heading;
                font-weight: $font-weight-semibold;
                font-size: 25px;
                line-height: 30px;
                height: 50px;
                margin-bottom: 30px;
                width: 280px;
                padding-left: 10px;
                background-color: transparent;
                color: $color-white;
                text-transform: uppercase;
                transition: color $duration ease-in-out;
            }
    
            .sub-menu {
                position: absolute;
                top: 0;
                left: 350px;
                width: 690px;
                height: 320px;
                background-color: $color-main;
                transition: all $duration ease-in-out;
                display: none;

                .nav-drop {
                    max-height: 310px;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    gap: 14px 85px;
                    margin-top: -15px;
                }

                .nav-child-item {
                    position: relative;
                    font-family: $font-family;
                    font-weight: $font-weight-normal;
                    font-size: $font-size--5;
                    line-height: 22px;
                    width: 370px;
                    min-width: 370px;
                    max-width: 370px;
                    padding-right: 45px;
                    color: $color-white;
                    border-bottom: 1px solid $color-white;
                    transition: all $duration;

                    &::before {
                        content: '\f345';
                        font: normal 20px/1 dashicons;
                        position: absolute;
                        right: 5px;
                        color: $color-white;
                        transition: all .25s;
                        top: 2px;
                        z-index: 2;
                    }   

                    &::after {
                        position: absolute;
                        content: "";
                        width: 35px;
                        height: 35px;
                        right: 0;
                        top: -5px;
                        background-color: $color-fifth;
                        opacity: 0;
                        clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
                        -webkit-clip-path: polygon(5% 25%, 50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
                        transition: all .25s;
                        z-index: 0;
                    }   

                    a {
                        position: relative;
                        width: 370px;
                        display: flex;
                        z-index: 3;
                        padding-bottom: 15px;
                        animation: unset !important;
                        transition: all $duration; 

                        &:focus {
                            color: $color-white!important;
                        }

                        &:hover {
                            color: $color-fifth!important;
                        }
                    }

                    &:hover, &:focus {
                        color: $color-fifth;
                        border-color: $color-fifth;
                        padding-left: 15px;

                        &:before {
                            color: $color-main;
                        }

                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }

            &.active,
            &.menu-item-has-children.active {
                .sub-menu {
                    display: block;
                }
            }
        }
    }

    &__button {
        // display: none;
        opacity: 0;
        position: absolute;
        z-index: -1;


        ~ .menu {
            clear: both;
            transform: scaleY(0);
            transform-origin: 50% 0;
            transition:transform .3s ease;
        }

        &:checked {
            ~ .nav-main__container {
                transform: translateY(0);

                .container .menu {
                    transform: scaleY(1);
                    display: flex;

                    li a {
                        animation: slide-left 1.2s ease-in-out 0s 1 normal forwards;
    
                        @keyframes slide-left {
                            from {
                                opacity: 0;
                                transform: translateX(-650px);
                            }
                        
                            to {
                                opacity: 1;
                                transform: translateX(0);
                            }
                        }
                    }
                }
            }

            ~ .nav-main__icon {
                background-color: transparent;
                &:before {
                    display: none;
                }

                .nav-main__icon--container {
                    display: none;
                    clip-path: polygon(5% 25%, 50% 0, 92% 25%, 92% 75%, 50% 100%, 5% 75%);
                    -webkit-clip-path: polygon(5% 25%, 50% 0, 92% 25%, 92% 75%, 50% 100%, 5% 75%);
                }

                &:not(.steps) {
                    .nav-main__icon__svg-container {
                        &:before,
                        &:after {
                            top: 0;
                        }
                    }
                }

                .nav-main__icon__close {
                    display: flex;
                    animation: .8s menuClose;
                    background: $color-white;
                    left: calc(50% + 520px);
                    top: 40px;
                    position: relative;
                    background: white;
                    position: fixed;
                    z-index: 15;
                    clip-path: polygon(5% 25%, 50% 0, 92% 25%, 92% 75%, 50% 100%, 5% 75%);
                    -webkit-clip-path: polygon(5% 25%, 50% 0, 92% 25%, 92% 75%, 50% 100%, 5% 75%);
                }
            }
        }
    }

    &__icon {
        cursor: pointer;
        width: 100%;
        height: 100%;
        margin: 0;
        margin-left: 0;
        background-color: transparent;

        &--container {
            position: relative;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            width: 50px;
            height: 50px;
            clip-path: polygon(5% 25%, 50% 0, 92% 25%, 92% 75%, 50% 100%, 5% 75%);
            -webkit-clip-path: polygon(5% 25%, 50% 0, 92% 25%, 92% 75%, 50% 100%, 5% 75%);
            transition: all $duration ease-out;


            &:before {
                position: absolute;
                content: "";
                background-image: url(/wp-content/themes/egletons/assets/src/images/sprite-images/home/forme.svg);
                top: 1px;
                left: 0;
                width: 49px;
                height: 49px;
                background-size: 49px;
                background-repeat: no-repeat;
                z-index: 1;
            }

            &:hover {
                background-color: $color-main;

                span {
                    background-color: $color-white;

                    &:before, &:after {
                        background-color: $color-white;
                    }
                }
            }

            &.focused {
                background-color: $color-main;

                span {
                    background-color: $color-white;

                    &:before, &:after {
                        background-color: $color-white;
                    }
                }
            }
        }
    
        &__burger {
            width: 18px;
            height: 12px;
            position: absolute;
        }
    
        &__close {
            display: none;
            width: 50px;
            height: 50px;
        }

        svg {
            display: none;
        }
    
    
        &__svg-container {
            position: relative;
            display: block;
            height: 2px;
            width: 16px;
            left: unset;
            background-color: $color-main;
            transition: all $duration ease-out;
    
            &:before, 
            &:after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                height: 100%;
                width: 16px;
                background-color: $color-main;
                transition: all $duration ease-out;
            }
    
            &:before {
                top: 5px;
            }
    
            &:after {
                top: -5px;
                height: 2px;
            } 
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .nav-main {
        min-width: unset;

        .menu {
            width: 325px;

            .nav-main-item {
                .nav-main-link {
                    font-size: 18px;
                }
                
                .sub-menu {
                    left: 325px;
                    width: 530px;
                    padding-left: 55px;
            
                    .nav-child-item {
                        width: 240px;
                        min-width: 240px;
                        max-width: 240px;

                        a {
                            width: 240px;
                        }
                    }
                    .nav-drop {
                        gap: 18px 50px;
                    }
                }
            }
        }

        &__icon {
            display: flex;
            justify-content: center;
            font-size: 0;
            width: 50px;
            padding: 0;
        }

        &__button:checked ~ .nav-main__icon .nav-main__icon__close {
            left: calc(50% + 410px);
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .nav-main {
        &__button:checked ~ .nav-main__icon .nav-main__icon__close {
            left: calc(50% + 265px);
        }

        &__container {
            transform: translateY(-100vh);
            height: 100vh;
        }

        &-link {
            width: 180px;
        }

        .menu .nav-main-item .sub-menu {
            left: 180px;
            padding-left: 115px;
            height: 380px;

            .nav-drop {
                flex-wrap: nowrap;
            }

            .nav-child-item {
                width: 330px;
                min-width: 330px;
                max-width: 330px;

                a {
                    width: 330px;
                }
            }
        }

        &__icon__svg-container {
            top: 1px;
        }
    }
}

// 640
@media screen and (max-width: $small) {

    .nav-main {
        &__button:checked ~ .nav-main__icon .nav-main__icon__close {
            left: calc(50% + 95px);
            top: 35px;
        }

        &__container {
            height: 100vh;
            transform: translateY(-100vh);

            .container {
                &:before {
                    width: 300px;
                    left: 10px;
                    top: 115px;
                }

                svg {
                    width: 215px;
                    height: 50px;
                }
            }
        }
        .menu {
            display: flex;
            flex-flow: column;
            position: absolute;
            top: 75px;
            width: 300px;
            padding-top: 65px;
            margin-bottom: 35px;

            .nav-main-item:not(:first-child) {
                .sub-menu {
                    margin-top: 70px;
                }
            }

            .nav-main-item:nth-child(3) {
                .sub-menu {
                    margin-top: 155px;
                }
            }

            .nav-main-item:nth-child(4) {
                .sub-menu {
                    margin-top: 235px;
                }
            }

            .nav-main-item {
                margin-bottom: 20px;

                .nav-main-link {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    max-width: 100%;
                    height: 60px;
                    padding: 0 20px;
                    background-color: $color-white;
                    color: $color-main;
                    justify-content: center;
                    margin-bottom: 0;
    
                    &:first-child {
                        padding: 0 20px;
                    }
                }
    
                &:last-child {
                    .nav-main-link {
                        border-bottom: 0;
    
                        &:after {
                            width: 0;
                            height: 0;
                            background: $color-bg--transparent;
                        }
                    }
                }
    
                &.menu-item-has-children:hover, &.menu-item-has-children:focus-within {
                    .sub-menu {
                        display: none;
                    }
                }
    
                &.active,
                &.menu-item-has-children.active {
                    background: $color-main;
                    box-shadow: 0 0 1px rgb(27 35 54 / 8%), 1px 1px 10px rgb(27 35 54 / 8%);
                    position: absolute;
                    top: 65px;
                    z-index: 2;
    
                    .nav-main-link {
                        border-bottom: 1px solid $color-gray;
                        color: $color-main;
                    }
    
                    .sub-menu {
                        display: flex;
                        position: relative;
                        padding: 30px 10px;
                        box-shadow: none;
                        left: 0;
                        background-color: unset;
                        width: 100%;
                        height: 100%;
    
                        .nav-drop {
                            column-count: 1;
                            gap: 5px 50px;
                            max-height: unset;
                            padding-bottom: 0;
                        }
    
                        .nav-child-item {
                            width: 280px;
                            min-width: 280px;
                            max-width: 280px;
                            font-size: $font-size--text-small;
                            padding-bottom: 10px;

                            &:after {
                                display: none;
                            }

                            a {
                                width: 280px;
                                
                                &:after {
                                    display: none;
                                }
                            }

                            &:hover {
                                &:hover {
                                    &:before {
                                        color: $color-fifth;
                                    }
                                }
                            }
                        }
    
                        .nav-grandchild-item {
                            a {
                                padding: 10px;
                            }
                        }
    
                        li:last-child {
                            a {
                                border-bottom: 0;
    
                                &::before {
                                    display: none;
                                }
    
                                &::after {
                                    top: 50%;
                                    transform: translate(0,-50%);
                                }
                            }
                        }
                    }
                }
    
            }
        }

        

        // Responsive button
        .nav-main_button {
            ~ .menu {
                clear: both;
                transform: scaleY(0);
                transform-origin: 50% 0;
                transition:transform .3s ease;
            }

            &:checked {
                ~ .menu {
                    transform: scaleY(1);
                }
                ~ .nav-main_icon {
                    .navicon {
                        background: $color-bg--transparent;

                        &:before {
                            transform: rotate(-45deg);
                        }

                        &:after {
                            transform: rotate(45deg);
                        }
                    }

                    &:not(.steps) {
                        .navicon {
                            &:before,
                            &:after {
                                top: 0;
                            }
                        }
                    }
                }
            }
        }

        // Responsive button label
        .nav-main_icon {
            display: flex;
            width: 100%;
            height: 75px;
            margin: 0;
            padding: 0;
            font-size: 1.375rem;
            font-weight: $font-weight;
            text-transform: uppercase; 
        }

        &__button:checked ~ .nav-main__icon {
            // animation: moveMobileCloseButton 1s ease-in-out 0.25s 1 normal forwards;
        }

        .nav-main__button:checked ~ .nav-main__container .container .menu li a {
            animation: none;
        }
    }
}