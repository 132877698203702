.scrollTopBtn {
    position: fixed;
    z-index: 100;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    clip-path: polygon(7% 25%,52% 0%,95% 25%,95% 75%,53% 100%,7% 75%);
    -webkit-clip-path: polygon(7% 25%, 50% 0, 95% 25%, 95% 75%, 53% 100%, 5% 75%);
    border: none;
    margin: 0;
    padding: 0;
    opacity: 0;
    transform: translateY(100px);
    transition: all .5s ease;

    &--show,
    &--show:focus  {
        opacity: 1;
        transform: translateY(0);
        background: $color-main;
    }

    svg {
        path {
            fill: $color-white;
            transition: $duration;
        }
    }

    &:hover {
        background: $color-second;

        svg {
            path {
                fill: $color-dark;
            }
        }
    }
  }