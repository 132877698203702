.footer {
    margin: 80px 0 10px;

    &__infos {
        display: flex;
        justify-content: space-between;
        padding-bottom: 50px;
        border-bottom: 1px solid $color-main;

        &__horaires {
            margin-right: 90px;
        }

        &__logo {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 30px;

            > svg {
                width: 190px;
                height: 30px;
                margin-left: -7px;
            }
        }

        &__title {
            font-family: $font-family--heading;
            font-weight: $font-weight-bold;
            font-size: $font-size--text-medium;
            line-height: 24px;
            color: $color-dark;
        }

        &__text {
            font-family: $font-family;
            font-weight: $font-weight-normal;
            font-size: $font-size--5;
            line-height: 22px;
            color: $color-dark;
            margin-top: 10px;
            margin-bottom: 30px;
        }

        &__phone {
            font-family: $font-family;
            font-weight: $font-weight-bold;
            font-size: $font-size--5;
            line-height: 22px;
            color: $color-dark;

            &:hover, &:focus {
                color: $color-main;
            }
        }
    }

    &__menu {
        padding: 30px 0;
        background: $color-white;

        ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        li {
            position: relative;

            a {
                font-family: $font-family--heading;
                font-weight: $font-weight-thin;
                font-size: $font-size--text-small;
                line-height: 22px;
                color: $color-dark;
                text-transform: uppercase;

                &:hover, &:focus {
                    color: $color-main;
                }
            }
        }
    }
}


// 1200
@media screen and (max-width: $large) {



}

// 960
@media screen and (max-width: $medium) {

    .footer {
        margin: 80px 0 20px;

        &__container {
            position: relative;
        }

        &__infos {
            flex-wrap: wrap;
            justify-content: flex-start;

            &__logo {
                width: 100%;
                margin-bottom: 40px;
            }

            &__horaires {
                margin-right: 0;
            }

            &__coordonnees {
                margin-right: 50px;
            }
        }

        &__button {
            position: absolute;
            right: 97px;
            bottom: 180px;
        }

        &__menu {
            ul {
                justify-content: flex-start;
                gap: 30px 50px;
            }
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        &__button {
            right: unset;
            left: 10px;
            bottom: unset;
            top: 350px;
        }

        &__infos {
            padding-bottom: 80px;
            
            &__horaires {
                margin-top: 40px;
            }
        }

        &__menu {
            li a {
                font-size: 0.840rem;
            }
        }
    }
}